import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

import { TransitionLink } from "../animation/TransitionLink"
import { ensurePublicURL } from "../general"

export const MarketSelect = ({ markets }) => {

  return (
    <Container className="market-box" fluid>
      <Row>
        {markets.map((market, i) => {
          const marketBackground = ensurePublicURL(market.node.market_image)
          return (
            <Col className="p-0 px-3" key={i} lg={4} xs={12}>
              <MarketHoverCard
                background={marketBackground.publicURL}
                subMarkets={market.node.sub_markets}
                name={market.node.market_name}
                marketSlug={market.node.market_slug}
              />
            </Col>
          )
        })}
      </Row>
      <Row>

      </Row>
    </Container>
  )
}
const MarketHoverCard = ({ background, name, marketSlug, subMarkets }) => {
  return (
    <div
      className="market-container market-hover-card"
      style={{ backgroundImage: "url(" + background + ")" }}
    >

      <div className="market-select-header">
        <h1 className="text-left pl-4">{name}</h1>
      </div>

      <div className="content-container py-5">
        <div className="text-left">
          {
            subMarkets.map((s, index) => (
              <h4 key={index} className="new sub-market">
                <TransitionLink to={`/${marketSlug}/${s.page_slug}`}>
                  {s.link_text}
                  <FontAwesomeIcon className="ml-3" icon={faChevronRight} />
                </TransitionLink>
              </h4>
            ))
          }

          <div className="pt-3">
            <span>CLICK A MARKET TO LEARN MORE</span>
          </div>
        </div>
      </div>
    </div>
  )
}

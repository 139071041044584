import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { useSpring, animated } from "react-spring"
import { Swiper, SwiperSlide } from "swiper/react"
import { TransitionLink } from "../animation/TransitionLink"

export const HeroSwiper = ({ secondSwiper, setFirstSwiper, slides }) => {
  const heroShowAnim = useSpring({
    width: "0%",
    from: { width: "100%" },
    config: { duration: 250 },
    delay: 2100,
  })

  return (
    <div className="hero-slider-wrap fl-wrap full-height">
      <div className="hero-slider fs-gallery-wrap fl-wrap full-height">
        <Swiper
          initialSlide={0}
          allowTouchMove={false}
          onSwiper={setFirstSwiper}
          loop={true}
          simulateTouch={false}
          pagination={{ clickable: true }}
          controller={{ control: secondSwiper }}
          spaceBetween={50}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
        >
          {slides.map((s, index) => (
            <SwiperSlide key={index}>
              <div className="half-hero-wrap">
                <animated.div
                  // style={heroShowAnim}
                  className="hero-anim-overlay"
                />
                {s.subtitle && <div className="rotate_text">{s.subtitle}</div>}

                <h1>
                  <div>{s.header_line_1}</div>
                  {s.header_line_2 && <div>{s.header_line_2}</div>}
                  {s.header_colored_text && (
                    <div>
                      <span>{s.header_colored_text}</span>
                    </div>
                  )}
                </h1>
                <h4>{s.excerpt}</h4>
                <TransitionLink
                  to={s.link_url}
                  className="half-hero-wrap_link ajax"
                >
                  {s.link_text}
                  <FontAwesomeIcon
                    className="ml-3"
                    size="lg"
                    icon={faArrowRight}
                  />
                </TransitionLink>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { TransitionLink } from "../animation/TransitionLink"
import { ensurePublicURL } from "../general"
import Fade from "react-reveal/Fade"
export const SolutionExcerpts = ({ solutions }) => {
  return (
    <Container className="p-0" fluid>
      <Row>
        {solutions.map((s, i) => {
          const solutionLogo = ensurePublicURL(s.excerpt_image)
          const previewImage = ensurePublicURL(s.landing_preview_image)

          return (
            <Col key={i} className="p-0" lg={6} xs={12}>
              <SolutionCard
                logo={solutionLogo.publicURL}
                alt={i === 1 || i === 2}
                linkClass={s.page_class}
                excerpt={s.description}
                linkSlug={s.page_slug}
                previewImg={previewImage.publicURL}
              />
            </Col>
          )
          
          // if (i < 4) {
          //   return (
          //     <Col key={i} className="p-0" lg={6} xs={12}>
          //       <SolutionCard
          //         logo={solutionLogo.publicURL}
          //         alt={i === 1 || i === 2}
          //         linkClass={s.page_class}
          //         excerpt={s.description}
          //         linkSlug={s.page_slug}
          //         previewImg={previewImage.publicURL}
          //       />
          //     </Col>
          //   )
          // }
          // return (
          //   <Col key={i} className="p-0" lg={12} xs={12}>
          //     <SolutionCardWide
          //       logo={solutionLogo.publicURL}
          //       linkClass={s.page_class}
          //       excerpt={s.description}
          //       linkSlug={s.page_slug}
          //       previewImg={previewImage.publicURL}
          //     />
          //   </Col>
          // )
        })}
      </Row>

      <Row>
        <Col className="p-0" lg={12}>
          <div style={{ background: "#02030c" }}>
            <div style={{ height: "100px" }} />
            <h2 className="section-header achievement-heading pb-2">
              <span>Revolutionizing</span> the energy Industry
            </h2>
            <div style={{ height: "200px" }} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}
const SolutionCard = ({
  alt,
  logo,
  excerpt,
  linkClass,
  linkSlug,
  previewImg,
}) => {
  const backgroundClass = alt ? "alt-back" : ""
  return (
    <div className={`solution-container ${backgroundClass}`}>
      <Fade>
        <div className="solution-logo-container mt-3 mb-4">
          <Image src={logo} />
        </div>
        <div className="solution-excerpt mt-4">
          <p className="m-auto">{excerpt}</p>
        </div>
        <div className="solution-link mt-5 mb-5">
          <TransitionLink to={`/solution/${linkSlug}`} className={linkClass}>
            LEARN MORE
          </TransitionLink>
        </div>
        {/* <div className="solution-image">
          <Fade bottom>
            <Image className="solution-excerpt-screen" fluid src={previewImg} />
          </Fade>
        </div> */}
      </Fade>
    </div>
  )
}
const SolutionCardWide = ({
  alt,
  logo,
  excerpt,
  linkClass,
  linkSlug,
  previewImg,
}) => {
  const backgroundClass = alt ? "alt-back" : ""
  return (
    <div className={`solution-container  pt-5 ${backgroundClass}`}>
      <Row className="pt-5">
        <Col lg={6}>
          <div className="solution-logo-container mt-3 mb-4">
            <Image src={logo} />
          </div>
          <div className="solution-excerpt mt-4">
            <p>{excerpt}</p>
          </div>
          <div className="solution-link mt-5 mb-5">
            <TransitionLink to={`/solution/${linkSlug}`} className={linkClass}>
              LEARN MORE
            </TransitionLink>
          </div>
        </Col>

        <Col lg={6}>
          <Fade right>
            <Image className="preview-right-image" fluid src={previewImg} />
          </Fade>
        </Col>
      </Row>
    </div>
  )
}

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { LandingPage } from "../components/landing/__LandingPage"

const IndexPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query LandingQuery {
          markets: allStrapiMarkets {
            edges {
              node {
                market_slug
                strapiId
                market_name
                sub_markets {
                  page_slug
                  id
                  link_text
                }
                market_image {
                  publicURL
                }
              }
            }
          }
          landing: strapiLandingPage {
            strapiId
            about_us {
              excerpt
              heading
              about_image {
                publicURL
              }
              specialty {
                heading
                icon {
                  publicURL
                }
              }
            }
            achievements {
              achievement_excerpt
              heading
              link_text
              link_url
            }
            articles {
              title
              published_at(formatString: "MMM DD")
              excerpt
              image {
                publicURL
              }
              id
            }
            clients {
              logo {
                publicURL
              }
              name
            }
            markets {
              market_image {
                publicURL
              }
              market_name
            }
            solutions {
              description
              excerpt_image {
                publicURL
              }
              landing_preview_image {
                publicURL
              }
              page_class
              page_slug
            }
            swiper_slides {
              excerpt
              header_colored_text
              header_line_1
              header_line_2
              link_text
              link_url
              slide_image {
                publicURL
              }
              subtitle
            }
          }
        }
      `}
      render={data => <LandingPage data={data} />}
    />
  )
}

export default IndexPage

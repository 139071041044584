import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { ensurePublicURL } from "../general"
export const ImageSwiper = ({ firstSwiper, setSecondSwiper, slides }) => {
  return (
    <div className="hero-slider-img hero-slider-wrap_halftwo">
      <div className="sec-lines"></div>
      <Swiper
        allowTouchMove={false}
        spaceBetween={50}
        loop={true}
        simulateTouch={false}
        onSwiper={setSecondSwiper}
        controller={{ control: firstSwiper }}
      >
        {slides.map((s, index) => {
          const sliderImage = ensurePublicURL(s.slide_image)
          return (
            <SwiperSlide key={index}>
              <div
                className="bg"
                style={{ backgroundImage: `url(${sliderImage.publicURL})` }}
              ></div>
              <div className="overlay"></div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { ensurePublicURL } from "../general"
import Fade from "react-reveal/Fade"

export const AboutUs = ({ data }) => {
  const aboutImage = ensurePublicURL(data.about_image)
  return (
    <div className="about-us-container">
      <Container className="about-flair">
        <Row className="full-height">
          <Col className="border-left" lg={4}></Col>
          <Col className="border-left border-right" lg={4}></Col>
          <Col className="border-right" lg={4}></Col>
        </Row>
      </Container>
      <h2 className="section-header">
        ABOUT <span>GRAVITATE</span>
      </h2>
      <Container>
        <Fade>
          <Row className="pt-5 about-us-first-div">
            <Col className="pt-5 text-left" xs={12} lg={4}>
              <h3 className="pb-3">{data.heading}</h3>

              <ReactMarkdown source={data.excerpt} />
            </Col>
            <Col className="responsive-pl-5 pt-5" xs={12} lg={4}>
              <h3 className="text-left pb-3">Our Specialties</h3>

              {data.specialty.map((spec, i) => {
                const containerClass = i === 0 ? "text-left" : "text-left pt-3"
                const specIcon = ensurePublicURL(spec.icon)

                return (
                  <div key={i} className={containerClass}>
                    <div>
                      <Image
                        className="mt-3 "
                        src={specIcon.publicURL}
                        thumbnail
                      />
                    </div>
                    <div>
                      <h5 className="pt-3">{spec.heading}</h5>
                    </div>
                  </div>
                )
              })}
            </Col>
            <Col className="pt-5 mobile-hide" xs={12} lg={4}>
              <Image src={aboutImage.publicURL} fluid />
            </Col>
          </Row>
          <div style={{ height: "120px" }} />
          <h2 className="section-header market-select pb-2">
            Select Your Market
          </h2>
          <div style={{ height: "40px" }} />
        </Fade>
      </Container>
    </div>
  )
}

import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import ReactMarkdown from "react-markdown"
import { TransitionLink } from "../animation/TransitionLink"
// import Fade from "react-reveal/Fade"

export const Achievements = ({ achievements }) => {
  return (
    <div className="achievement-container">
      <Container className="achievement-flair">
        <Row className="full-height">
          <Col className="border-left" lg={4}></Col>
          <Col className="border-left border-right" lg={4}></Col>
          <Col className="border-right" lg={4}></Col>
        </Row>
      </Container>
      <Container className="nudge-up">
        <Row className="pt-4 ">
          <Col className="p-0 mt-4" xs={12} lg={6}>
            {/* <Fade left> */}
              <AchievementCard
                header={achievements[0].heading}
                excerpt={
                  <ReactMarkdown source={achievements[0].achievement_excerpt} />
                }
                light
                backgroundHex="#FFFFFF"
              />
            {/* </Fade> */}
          </Col>
          <Col className="p-0 mt-4" xs={12} lg={6}>
            {/* <Fade right> */}
              <AchievementCard
                header={achievements[1].heading}
                excerpt={
                  <ReactMarkdown source={achievements[1].achievement_excerpt} />
                }
                linkUrl={achievements[1].link_url}
                wide
                backgroundHex="#21284F"
              />
            {/* </Fade> */}
          </Col>
        </Row>
        <Row className=" pb-4">
          <Col className="p-0 mt-4" xs={12} lg={6}>
            {/* <Fade left> */}
              <AchievementCard
                header={achievements[2].heading}
                excerpt={
                  <ReactMarkdown source={achievements[2].achievement_excerpt} />
                }
                linkUrl={achievements[2].link_url}
                light
                backgroundHex="#DEE2E6"
              />
            {/* </Fade> */}
          </Col>
          <Col className="p-0 mt-4" xs={12} lg={6}>
            {/* <Fade right> */}
              <AchievementCard
                header={achievements[3].heading}
                excerpt={
                  <ReactMarkdown source={achievements[3].achievement_excerpt} />
                }
                linkUrl={achievements[3].link_url}
                backgroundHex="#212529"
              />
            {/* </Fade> */}
          </Col>
          <Col className="p-0 mt-4" xs={12} lg={9}>
            {/* <Fade left> */}
              <AchievementCard
                header={achievements[4].heading}
                excerpt={
                  <ReactMarkdown source={achievements[4].achievement_excerpt} />
                }
                linkUrl={achievements[4].link_url}
                backgroundHex="#0F1121"
              />
            {/* </Fade> */}
          </Col>
          <Col className="p-0 mt-4" xs={12} lg={3}>
            {/* <Fade right> */}
              <AchievementCard
                contact
                linkUrl="/contact"
                header="Want To Hear More about what we can do?"
                backgroundHex="#4BADE9"
              />
            {/* </Fade> */}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
const AchievementCard = ({
  backgroundHex,
  tall,
  light,
  header,
  excerpt,
  linkUrl,
  wide,
  contact,
}) => {
  const ctaHeaderClass = contact ? "contact" : ""
  return (
    <div
      style={{
        minHeight: tall ? 500 : 280,
        height: "100%",
        width: "100%",
        backgroundColor: backgroundHex,
      }}
      className={"achievement-card " + (light ? "light " : "dark ")}
    >
      <div className="flex-1">
        <h3
          className={"pb-4 " + ctaHeaderClass}
          style={{ color: light ? "darkslategrey" : "white" }}
        >
          {header}
        </h3>
      </div>
      <div
        className="flex-2 text-left"
        style={{ color: light ? "grey" : "white" }}
      >
        {excerpt}
      </div>
      <div className="flex-1">
        {linkUrl && (
          <div className="pt-2">
            <TransitionLink to={linkUrl} className="custom-underline">
              {contact ? "GET IN TOUCH" : "LEARN MORE"}
              <FontAwesomeIcon className="ml-3" icon={faChevronRight} />
            </TransitionLink>
          </div>
        )}
      </div>
    </div>
  )
}

import React from "react"
import Layout from "../layout"
import SwiperCore, {
  Controller,
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"
import { LandingSwiper } from "./_LandingSwiper"
import { AboutUs } from "./_AboutUs"
import { MarketSelect } from "./_MarketSelect"
import { Container } from "react-bootstrap"
import { SolutionExcerpts } from "./_SolutionExcerpts"
import { Achievements } from "./_Achievements"
import { Insights } from "./_Insights"
import { Footer } from "./_Footer"
import { PageLoadAnimate } from "../animation/PageLoadAnimate"

// install Swiper components
SwiperCore.use([Controller, Autoplay, Navigation, Pagination, Scrollbar])

export const LandingPage = ({ data }) => {
  const landingData = data.landing
  const markets = data.markets.edges
  const insights = landingData.articles
  return (
    <Layout
      title="Gravitate - Power Your Business. Empower Your Customers. Drive Digital Change."
      description="We aim to drive value for energy and commodity organizations by delivering AI enabled collaboration and decision support software."
      image={landingData.swiper_slides[0].slide_image.publicURL}
    >
      <PageLoadAnimate />
      <LandingSwiper slides={landingData.swiper_slides} />
      <Container className="rest-container" fluid>
        {/* <ClientBrag clients={landingData.clients} /> */}
        <AboutUs data={landingData.about_us} />
        <MarketSelect markets={markets} />
        <SolutionExcerpts solutions={landingData.solutions} />
        <Achievements achievements={landingData.achievements} />
        <Insights insights={insights} />
        <Footer />
      </Container>
    </Layout>
  )
}

import React, { useEffect } from "react"

export const HoverDots = () => {
  const initCanvas = () => {
    var block = document.getElementById("dots")
    var canvas = document.querySelector("canvas")
    var width = block.offsetWidth
    var height = block.offsetHeight
    var ctx = canvas.getContext("2d")
    ctx.width = width
    ctx.height = height
    var devicePixelRatio = window.devicePixelRatio || 1,
      backingStoreRatio =
        ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio ||
        1
    var ratio = devicePixelRatio / backingStoreRatio
    canvas.width = width * ratio
    canvas.height = height * ratio
    ctx.scale(ratio, ratio)
    var mouseX = undefined
    var mouseY = undefined

    function Circle(x, y) {
      this.x = x
      this.y = y
      this.distance = 10
      this.radians = 0
      this.draw = function () {
        ctx.beginPath()
        ctx.strokeStyle = "rgba(151, 151, 151, .3)"
        ctx.moveTo(this.x + 3, this.y)
        ctx.lineTo(this.x + 3, this.y + 6)
        ctx.moveTo(this.x, this.y + 3)
        ctx.lineTo(this.x + 6, this.y + 3)
        ctx.stroke()
      }
      this.update = function () {
        if (mouseX > -1) {
          var k1 = mouseY - y
          var k2 = mouseX - x
          var tan = k1 / k2
          var yrad = Math.atan(tan)
          if (mouseX < x) {
            yrad = Math.PI - Math.atan(-tan)
          }
          this.x = x + Math.cos(yrad) * this.distance
          this.y = y + Math.sin(yrad) * this.distance
        }
        this.draw()
      }
    }
    var circlesArray = []
    var gutter = 40
    var countW = Math.floor(width / gutter)
    var countH = Math.floor(height / gutter)

    for (var i = 0; i < countH; i++) {
      for (var t = 0; t < countW; t++) {
        var x = gutter * t
        var y = gutter * i
        circlesArray.push(new Circle(x, y))
      }
    }
    var loop = function () {
      ctx.clearRect(0, 0, ctx.width, ctx.height)
      for (var i = 0; i < circlesArray.length; i++) {
        circlesArray[i].update()
      }
    }
    document.addEventListener("mousemove", function (e) {
      var parent = document.getElementById("dots")
      if (!parent) return
      var relX = e.pageX - parent.offsetLeft - 160
      var relY = e.pageY - parent.offsetTop - 170
      mouseX = relX
      mouseY = relY
      ctx.clearRect(0, 0, ctx.width, ctx.height)
      for (var i = 0; i < circlesArray.length; i++) {
        circlesArray[i].update()
      }
      loop()
    })
    loop()
  }
  useEffect(() => {
    initCanvas()
  }, [])
  return <canvas style={{ width: "100%", height: "100%" }}></canvas>
}

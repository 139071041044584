import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import placeholder from "../../images/stock/placeholder.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { ensurePublicURL } from "../general"
import { TransitionLink } from "../animation/TransitionLink"
import Fade from "react-reveal/Fade"

export const Insights = ({ insights }) => {
  return (
    <div className="insights-container">
      <Fade>
        <h2 className="section-header ">
          RECENT <span>INSIGHTS</span>
        </h2>
        <Container fluid className="insight-preview-container">
          <Row className="preview-row">
            <Col lg={6}>
              <Fade>
                <InsightPreviewSmall insight={insights[0]} />
              </Fade>
              <Fade>
                <InsightPreviewSmall insight={insights[1]} />
              </Fade>
            </Col>
            <Col className="full-height" lg={6}>
              <Fade>
                {" "}
                <InsightPreviewLarge insight={insights[2]} />
              </Fade>
            </Col>
          </Row>
        </Container>

        <div style={{ height: 100 }} />
      </Fade>
    </div>
  )
}
const InsightPreviewSmall = ({ insight }) => {
  const insightImage = ensurePublicURL(insight.image)
  return (
    <div className="insight-card">
      <div
        className="ml-5 insight-background"
        style={{
          backgroundImage: "url(" + insightImage.publicURL + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="insight-content">
          <Row style={{ height: "100%" }}>
            <Col lg={2} className="insight-date-container">
              <p>{insight.published_at}</p>
            </Col>
            <Col lg={10}>
              <div>
                <h3 className="text-left pr-3">{insight.title}</h3>
              </div>
              <div className="mt-5">
                <TransitionLink
                  to={`/article/${insight.id}`}
                  className="custom-underline"
                >
                  READ MORE
                  <FontAwesomeIcon className="mr-3" icon={faChevronRight} />
                </TransitionLink>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

const InsightPreviewLarge = ({ insight }) => {
  const insightImage = ensurePublicURL(insight.image)
  return (
    <div className="insight-card large">
      <div
        className="insight-background"
        style={{
          backgroundImage: "url(" + insightImage.publicURL + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="insight-content">
          <div className="flex-1 text-left">
            <p className="insight-preview-date">{insight.published_at}</p>
          </div>
          <div className="flex-1">
            <h3 className="text-left pr-4">{insight.title}</h3>
          </div>
          <div className="flex-2">
            <p className="text-left pt-3 pr-4">{insight.excerpt}</p>
          </div>
          <div className="flex-1">
            <div className="pt-2">
              <TransitionLink
                to={`/article/${insight.id}`}
                className="custom-underline"
              >
                READ MORE
                <FontAwesomeIcon className="mr-3" icon={faChevronRight} />
              </TransitionLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { useSpring, animated } from "react-spring"
import { PageSpinner } from "./PageSpinner"

export const PageLoadAnimate = () => {
  const pageLoadAnim = useSpring({
    top: -1800,
    from: { top: 0 },
    config: {
      clamp: true,
      tension: 70,
      mass: 10,
      friction: 0,
    },
    delay: 1500,
  })
  return (
    <animated.div style={pageLoadAnim} className="page-load-overlay">
      <PageSpinner />
    </animated.div>
  )
}


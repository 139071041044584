import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSpring, animated } from "react-spring"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { ImageSwiper } from "./ImageSwiper"
import { VerticalBar } from "../sitewide/VerticalBar"
import { HeroSwiper } from "./HeroSwiper"
import { HoverDots } from "../sitewide/HoverDots"
import { TransitionLink } from "../animation/TransitionLink"

export const LandingSwiper = ({ slides }) => {
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  const optionPanelAnim = useSpring({
    bottom: 0,
    from: { bottom: -60 },
    delay: 2400,
  })

  return (
    <div className="main">
      <VerticalBar />
      <div id="wrapper">
        <div className="content-holder" data-pagetitle="Home Half Slider">
          <div className="content full-height hidden-item home-half-slider">
            <div className="hero-canvas-wrap">
              <div className="dots gallery__dots" id="dots" data-dots="">
                <HoverDots />
              </div>
            </div>
            <animated.div
              style={optionPanelAnim}
              className="option-panel bot-element"
            >
              <TransitionLink to="/contact" className="ajax start-btn color-bg">
                <span className="mr-1">Contact Us</span>
                <FontAwesomeIcon size="lg" icon={faArrowRight} />
              </TransitionLink>
            </animated.div>

            <HeroSwiper
              secondSwiper={secondSwiper}
              setFirstSwiper={setFirstSwiper}
              slides={slides}
            />
            <ImageSwiper
              slides={slides}
              firstSwiper={firstSwiper}
              setSecondSwiper={setSecondSwiper}
            />
            
          </div>
        </div>
      </div>
    </div>
  )
}
